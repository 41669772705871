<template>
    <div>
        <div class="d-flex justify-content-sm-between justify-content-xl-center mt-90 row mx-auto container">
            <div class="col-lg-5 col-xl-4 pr-xs-0 pr-sm-0 pr-xl-10 pl-0">
                <div class="col-12 px-0 d-flex justify-content-xs-center justify-content-sm-center justify-content-xl-start align-items-end">
                    <i class="fas fa-file-contract fw-300 mb-1 fs-35"></i>
                    <h5 class="mb-0 fw-600 ml-3">
                        Relatório de trabalho
                    </h5>
                </div>
                <p class="mt-10 fw-500 fs-15 text-xs-center text-sm-center text-xl-left">
                    Receba um relatório detalhado de todo trabalho realizado, incluindo fotos,
                    filmagens, e muitas outras provas reais, tudo documentado e legalmente aceito.
                </p>
            </div>
            <div class="col-lg-5 col-xl-4 mt-xs-25 mt-sm-25 mt-lg-0 px-xs-0 px-sm-0 px-xl-10">
                <div class="col-12 px-0 d-flex justify-content-xs-center justify-content-sm-center justify-content-xl-start align-items-end">
                    <i class="fas fa-rocket fw-300 mb-1 fs-35"></i>
                    <h5 class="mb-0 fw-600 ml-3">
                        Resultado garantido
                    </h5>
                </div>
                <p class="mt-10 fw-500 fs-15 text-xs-center text-sm-center text-xl-left">
                    Tenha um resultado rápido e garantido com nossos métodos
                    de trabalhos super modernos e efetivos.
                </p>
            </div>
            <div class="col-lg-5 mt-xs-25 mt-sm-25 mt-xl-0 col-xl-4 pl-xs-0 pl-sm-0 pl-xl-10 pr-0">
                <div class="col-12 px-0 d-flex justify-content-xs-center justify-content-sm-center justify-content-xl-start align-items-end">
                    <i class="far fa-lightbulb fw-300 mb-1 fs-35"></i>
                    <h5 class="mb-0 fw-600 ml-3">
                        Consultoria em segurança
                    </h5>
                </div>
                <p class="mt-10 fw-500 fs-15 text-xs-center text-sm-center text-xl-left">
                    Além de todo o trabalho sobre a sua empresa que iremos lhe apresentar,
                    nós ainda vamos fornecer a você informações valiosas de como você pode
                    manter sua empresa segura daqui em diante.
                </p>
            </div>
            <div class="col-lg-5 col-xl-4 mt-25 pr-xs-0 pr-sm-0 pr-xl-10 pl-0">
                <div class="col-12 px-0 d-flex justify-content-xs-center justify-content-sm-center justify-content-xl-start align-items-end">
                    <i class="fas fa-shield-alt fw-300 mb-1 fs-35"></i>
                    <h5 class="mb-0 fw-600 ml-2">
                        Segurança e tranquilidade
                    </h5>
                </div>
                <p class="mt-10 fw-500 fs-15 text-xs-center text-sm-center text-xl-left">
                    Trabalhamos com profissionais altamente qualificados e
                    as mais modernas técnicas de investigação, que poderão lhe
                    atender, de acordo com a sua necessidade.
                </p>
            </div>
            <div class="col-lg-5 col-xl-4 mt-25 px-xs-0 px-sm-0 px-xl-10">
                <div class="col-12 px-0 d-flex justify-content-xs-center justify-content-sm-center justify-content-xl-start align-items-end">
                    <i class="fas fa-handshake fw-300 mb-1 fs-35"></i>
                    <h5 class="mb-0 fw-600 ml-3">
                        Procedimentos aprovados
                    </h5>
                </div>
                <p class="mt-10 fw-500 fs-15 text-xs-center text-sm-center text-xl-left">
                    Nossos métodos de trabalho serão acordados com o contratante.
                    Afim de elaborar o plano e como será a ação dos nossos detectives.
                </p>
            </div>
            <div class="col-lg-5 col-xl-4 mt-25 pl-xs-0 pl-sm-0 pl-xl-10 pr-0">
                <div class="col-12 px-0 d-flex justify-content-xs-center justify-content-sm-center justify-content-xl-start align-items-end">
                    <i class="far fa-camera fw-300 mb-1 fs-35"></i>
                    <h5 class="mb-0 fw-600 ml-3">
                        Provas legalmente reais
                    </h5>
                </div>
                <p class="mt-10 fw-500 fs-15 text-xs-center text-sm-center text-xl-left">
                    Nossa equipe vai apresentar uma solução para o
                    seu problema, por meio de provas reais (imagens, áudios e documentos) que comprovem ou não a suspeita.
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>

</style>
