import { render, staticRenderFns } from "./btn-azul-ligue-agora.vue?vue&type=template&id=59a48fbf&scoped=true&"
import script from "./btn-azul-ligue-agora.vue?vue&type=script&lang=js&"
export * from "./btn-azul-ligue-agora.vue?vue&type=script&lang=js&"
import style0 from "./btn-azul-ligue-agora.vue?vue&type=style&index=0&id=59a48fbf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a48fbf",
  null
  
)

export default component.exports