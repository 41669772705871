import { render, staticRenderFns } from "./btn-outline-fale-no-whatsapp.vue?vue&type=template&id=8994427c&scoped=true&"
import script from "./btn-outline-fale-no-whatsapp.vue?vue&type=script&lang=js&"
export * from "./btn-outline-fale-no-whatsapp.vue?vue&type=script&lang=js&"
import style0 from "./btn-outline-fale-no-whatsapp.vue?vue&type=style&index=0&id=8994427c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8994427c",
  null
  
)

export default component.exports