<template>
    <div>
        <a href="tel:+5541995190579" class="btn btn-info px-25 d-flex align-items-center justify-content-center fw-600 border-radius-20">
            <i class="fa fa-phone-alt mr-2 fw-400"></i> Ligue agora
        </a>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
    .fa-phone-alt{
        margin-bottom: 2px;
    }
</style>
