import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/detective-privado-no-norte-de-portugal',
    component: () => import('../components/estados/Norte/RouterViewNorte.vue'),
    children: [
      {
        path: '/',
        component: () => import('../components/estados/Norte/cidades/Norte.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-amarante',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-amarante/Amarante.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-arcos-de-valdevez',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-arcos-de-valdevez/Arcos-De-Valdevez.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-braga',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-braga/Braga.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-bragança',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-bragança/Bragança.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-calheta-(madeira)',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-calheta-(madeira)/Calheta-(madeira).vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-câmara-de-lobos',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-câmara-de-lobos/Câmara-De-Lobos.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-caminha',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-caminha/Caminha.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-castelo-de-paiva',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-castelo-de-paiva/Castelo-De-Paiva.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-chaves',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-chaves/Chaves.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-espinho',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-espinho/Espinho.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-esposende',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-esposende/Esposende.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-fafe',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-fafe/Fafe.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-felgueiras',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-felgueiras/Felgueiras.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-funchal',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-funchal/Funchal.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-gondomar',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-gondomar/Gondomar.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-guimarães',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-guimarães/Guimarães.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-lamego',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-lamego/Lamego.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-machico',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-machico/Machico.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-maia',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-maia/Maia.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-marco-de-canaveses',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-marco-de-canaveses/Marco-De-Canaveses.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-matosinhos',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-matosinhos/Matosinhos.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-miranda-do-douro',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-miranda-do-douro/Miranda-Do-Douro.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-mirandela',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-mirandela/Mirandela.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-montalegre',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-montalegre/Montalegre.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-oliveira-de-azeméis',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-oliveira-de-azeméis/Oliveira-De-Azeméis.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-penafiel',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-penafiel/Penafiel.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-peso-da-régua',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-peso-da-régua/Peso-Da-Régua.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-ponta-do-sol',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-ponta-do-sol/Ponta-Do-Sol.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-ponte-da-barca',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-ponte-da-barca/Ponte-Da-Barca.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-porto',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-porto/Porto.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-porto-moniz',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-porto-moniz/Porto-Moniz.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-porto-santo',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-porto-santo/Porto-Santo.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-póvoa-de-varzim',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-póvoa-de-varzim/Póvoa-De-Varzim.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-ribeira-brava',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-ribeira-brava/Ribeira-Brava.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-santa-cruz',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-santa-cruz/Santa-Cruz.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-santa-maria-da-feira',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-santa-maria-da-feira/Santa-Maria-Da-Feira.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-santana',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-santana/Santana.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-santo-tirso',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-santo-tirso/Santo-Tirso.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-são-joão-da-madeira',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-são-joão-da-madeira/São-João-Da-Madeira.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-são-vicente',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-são-vicente/São-Vicente.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-tarouca',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-tarouca/Tarouca.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-vale-de-cambra',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-vale-de-cambra/Vale-De-Cambra.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-valença',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-valença/Valença.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-valongo',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-valongo/Valongo.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-viana-do-castelo',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-viana-do-castelo/Viana-Do-Castelo.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-vila-flor',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-vila-flor/Vila-Flor.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-vila-nova-de-cerveira',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-vila-nova-de-cerveira/Vila-Nova-De-Cerveira.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-vila-nova-de-famalicão',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-vila-nova-de-famalicão/Vila-Nova-De-Famalicão.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-vila-nova-de-foz-côa',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-vila-nova-de-foz-côa/Vila-Nova-De-Foz-Côa.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-vila-nova-de-gaia',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-vila-nova-de-gaia/Vila-Nova-De-Gaia.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-vila-real',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-vila-real/Vila-Real.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-vimioso',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-vimioso/Vimioso.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-viseu',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-viseu/Viseu.vue')
      },
      {
        path: '/detective-privado-no-norte-de-portugal/detective-privado-em-vizela',
        component: () => import('../components/estados/Norte/cidades/detective-privado-em-vizela/Vizela.vue')
      }
    ]
  },
  {
    path: '/detective-privado-no-sul-de-portugal',
    component: () => import('../components/estados/Sul/RouterViewSul.vue'),
    children: [
      {
        path: '/',
        component: () => import('../components/estados/Sul/cidades/Sul.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-albufeira',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-albufeira/Albufeira.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-alcácer-do-sal',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-alcácer-do-sal/Alcácer-Do-Sal.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-alcoutim',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-alcoutim/Alcoutim.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-aljezur',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-aljezur/Aljezur.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-beja',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-beja/Beja.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-borba',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-borba/Borba.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-castro-marim',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-castro-marim/Castro-Marim.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-elvas',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-elvas/Elvas.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-estremoz',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-estremoz/Estremoz.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-Évora',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-Évora/Évora.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-faro',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-faro/Faro.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-lagoa-(algarve)',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-lagoa-(algarve)/Lagoa-(algarve).vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-lagos',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-lagos/Lagos.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-loulé',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-loulé/Loulé.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-monchique',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-monchique/Monchique.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-montemor-o-novo',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-montemor-o-novo/Montemor-O-Novo.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-moura',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-moura/Moura.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-olhão',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-olhão/Olhão.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-ponte-de-sor',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-ponte-de-sor/Ponte-De-Sor.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-portalegre',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-portalegre/Portalegre.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-portimão',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-portimão/Portimão.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-reguengos-de-monsaraz',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-reguengos-de-monsaraz/Reguengos-De-Monsaraz.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-santiago-do-cacém',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-santiago-do-cacém/Santiago-Do-Cacém.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-são-brás-de-alportel',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-são-brás-de-alportel/São-Brás-De-Alportel.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-serpa',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-serpa/Serpa.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-silves',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-silves/Silves.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-sines',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-sines/Sines.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-tavira',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-tavira/Tavira.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-vendas-novas',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-vendas-novas/Vendas-Novas.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-vila-do-bispo',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-vila-do-bispo/Vila-Do-Bispo.vue')
      },
      {
        path: '/detective-privado-no-sul-de-portugal/detective-privado-em-vila-real-de-santo-antónio',
        component: () => import('../components/estados/Sul/cidades/detective-privado-em-vila-real-de-santo-antónio/Vila-Real-De-Santo-António.vue')
      }
    ]
  },
  {
    path: '/detective-privado-no-centro-de-portugal',
    component: () => import('../components/estados/Centro/RouterViewCentro.vue'),
    children: [
      {
        path: '/',
        component: () => import('../components/estados/Centro/cidades/Centro.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-abrantes',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-abrantes/Abrantes.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-Águeda',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-Águeda/Águeda.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-aguiar-da-beira',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-aguiar-da-beira/Aguiar-Da-Beira.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-albergaria-a-velha',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-albergaria-a-velha/Albergaria-A-Velha.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-alcanena',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-alcanena/Alcanena.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-alcobaça',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-alcobaça/Alcobaça.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-alcochete',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-alcochete/Alcochete.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-alenquer',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-alenquer/Alenquer.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-almada',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-almada/Almada.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-almeida',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-almeida/Almeida.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-alvaiázere',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-alvaiázere/Alvaiázere.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-amadora',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-amadora/Amadora.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-anadia',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-anadia/Anadia.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-angra-do-heroísmo',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-angra-do-heroísmo/Angra-Do-Heroísmo.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-ansião',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-ansião/Ansião.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-arganil',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-arganil/Arganil.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-arruda-dos-vinhos',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-arruda-dos-vinhos/Arruda-Dos-Vinhos.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-aveiro',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-aveiro/Aveiro.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-barreiro',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-barreiro/Barreiro.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-batalha',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-batalha/Batalha.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-belmonte',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-belmonte/Belmonte.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-bombarral',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-bombarral/Bombarral.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-cadaval',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-cadaval/Cadaval.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-caldas-da-rainha',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-caldas-da-rainha/Caldas-Da-Rainha.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-calheta-(são-jorge)',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-calheta-(são-jorge)/Calheta-(são-Jorge).vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-cantanhede',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-cantanhede/Cantanhede.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-carregal-do-sal',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-carregal-do-sal/Carregal-Do-Sal.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-cascais',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-cascais/Cascais.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-castanheira-de-pêra',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-castanheira-de-pêra/Castanheira-De-Pêra.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-castelo-branco',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-castelo-branco/Castelo-Branco.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-castro-daire',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-castro-daire/Castro-Daire.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-celorico-da-beira',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-celorico-da-beira/Celorico-Da-Beira.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-coimbra',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-coimbra/Coimbra.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-condeixa-a-nova',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-condeixa-a-nova/Condeixa-A-Nova.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-constância',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-constância/Constância.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-corvo',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-corvo/Corvo.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-covilhã',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-covilhã/Covilhã.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-entroncamento',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-entroncamento/Entroncamento.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-estarreja',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-estarreja/Estarreja.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-ferreira-do-zêzere',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-ferreira-do-zêzere/Ferreira-Do-Zêzere.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-figueira-da-foz',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-figueira-da-foz/Figueira-Da-Foz.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-figueira-de-castelo-rodrigo',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-figueira-de-castelo-rodrigo/Figueira-De-Castelo-Rodrigo.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-figueiró-dos-vinhos',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-figueiró-dos-vinhos/Figueiró-Dos-Vinhos.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-fornos-de-algodres',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-fornos-de-algodres/Fornos-De-Algodres.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-fundão',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-fundão/Fundão.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-góis',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-góis/Góis.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-gouveia',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-gouveia/Gouveia.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-guarda',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-guarda/Guarda.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-horta',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-horta/Horta.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-idanha-a-nova',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-idanha-a-nova/Idanha-A-Nova.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-Ílhavo',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-Ílhavo/Ílhavo.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-lagoa-(são-miguel)',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-lagoa-(são-miguel)/Lagoa-(são-Miguel).vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-lajes-das-flores',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-lajes-das-flores/Lajes-Das-Flores.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-lajes-do-pico',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-lajes-do-pico/Lajes-Do-Pico.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-leiria',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-leiria/Leiria.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-loures',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-loures/Loures.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-lourinhã',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-lourinhã/Lourinhã.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-lousã',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-lousã/Lousã.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-mação',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-mação/Mação.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-mafra',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-mafra/Mafra.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-mangualde',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-mangualde/Mangualde.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-manteigas',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-manteigas/Manteigas.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-marinha-grande',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-marinha-grande/Marinha-Grande.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-mealhada',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-mealhada/Mealhada.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-meda',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-meda/Meda.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-mira',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-mira/Mira.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-miranda-do-corvo',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-miranda-do-corvo/Miranda-Do-Corvo.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-moita',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-moita/Moita.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-montemor-o-velho',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-montemor-o-velho/Montemor-O-Velho.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-montijo',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-montijo/Montijo.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-mortágua',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-mortágua/Mortágua.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-murtosa',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-murtosa/Murtosa.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-nazaré',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-nazaré/Nazaré.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-nordeste',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-nordeste/Nordeste.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-Óbidos',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-Óbidos/Óbidos.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-odivelas',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-odivelas/Odivelas.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-oeiras',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-oeiras/Oeiras.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-oleiros',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-oleiros/Oleiros.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-oliveira-de-frades',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-oliveira-de-frades/Oliveira-De-Frades.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-oliveira-do-bairro',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-oliveira-do-bairro/Oliveira-Do-Bairro.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-oliveira-do-hospital',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-oliveira-do-hospital/Oliveira-Do-Hospital.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-ourém',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-ourém/Ourém.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-ovar',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-ovar/Ovar.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-palmela',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-palmela/Palmela.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-pampilhosa-da-serra',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-pampilhosa-da-serra/Pampilhosa-Da-Serra.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-pedrógão-grande',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-pedrógão-grande/Pedrógão-Grande.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-penacova',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-penacova/Penacova.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-penalva-do-castelo',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-penalva-do-castelo/Penalva-Do-Castelo.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-penamacor',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-penamacor/Penamacor.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-penela',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-penela/Penela.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-peniche',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-peniche/Peniche.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-pinhel',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-pinhel/Pinhel.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-pombal',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-pombal/Pombal.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-ponta-delgada',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-ponta-delgada/Ponta-Delgada.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-porto-de-mós',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-porto-de-mós/Porto-De-Mós.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-praia-da-vitória',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-praia-da-vitória/Praia-Da-Vitória.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-proença-a-nova',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-proença-a-nova/Proença-A-Nova.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-ribeira-grande',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-ribeira-grande/Ribeira-Grande.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-sabugal',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-sabugal/Sabugal.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-santa-comba-dão',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-santa-comba-dão/Santa-Comba-Dão.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-santa-cruz-da-graciosa',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-santa-cruz-da-graciosa/Santa-Cruz-Da-Graciosa.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-santa-cruz-das-flores',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-santa-cruz-das-flores/Santa-Cruz-Das-Flores.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-são-pedro-do-sul',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-são-pedro-do-sul/São-Pedro-Do-Sul.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-são-roque-do-pico',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-são-roque-do-pico/São-Roque-Do-Pico.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-sardoal',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-sardoal/Sardoal.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-sátão',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-sátão/Sátão.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-seia',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-seia/Seia.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-seixal',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-seixal/Seixal.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-sertã',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-sertã/Sertã.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-sesimbra',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-sesimbra/Sesimbra.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-setúbal',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-setúbal/Setúbal.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-sever-do-vouga',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-sever-do-vouga/Sever-Do-Vouga.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-sintra',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-sintra/Sintra.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-sobral-de-monte-agraço',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-sobral-de-monte-agraço/Sobral-De-Monte-Agraço.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-soure',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-soure/Soure.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-tábua',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-tábua/Tábua.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-tomar',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-tomar/Tomar.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-tondela',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-tondela/Tondela.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-torres-novas',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-torres-novas/Torres-Novas.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-torres-vedras',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-torres-vedras/Torres-Vedras.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-trancoso',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-trancoso/Trancoso.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-vagos',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-vagos/Vagos.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-velas',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-velas/Velas.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-vila-de-rei',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-vila-de-rei/Vila-De-Rei.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-vila-franca-de-xira',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-vila-franca-de-xira/Vila-Franca-De-Xira.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-vila-franca-do-campo',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-vila-franca-do-campo/Vila-Franca-Do-Campo.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-vila-nova-da-barquinha',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-vila-nova-da-barquinha/Vila-Nova-Da-Barquinha.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-vila-nova-de-paiva',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-vila-nova-de-paiva/Vila-Nova-De-Paiva.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-vila-nova-de-poiares',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-vila-nova-de-poiares/Vila-Nova-De-Poiares.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-vila-velha-de-ródão',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-vila-velha-de-ródão/Vila-Velha-De-Ródão.vue')
      },
      {
        path: '/detective-privado-no-centro-de-portugal/detective-privado-em-vouzela',
        component: () => import('../components/estados/Centro/cidades/detective-privado-em-vouzela/Vouzela.vue')
      }
    ]
  }
//   {
//     path: '/detective-privado-Braga',
//     component: () => import('../components/estados/detective-privado-braga/RouterView.vue'),
//     children: [
//       {
//         path: '/',
//         component: () => import('../components/estados/detective-privado-braga/index.vue')
//       }
//     ]
//   }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
